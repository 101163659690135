<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Item
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-4 text-center">

        <validation-observer ref="observer">
          <v-form>
            <v-container class="pa-0">
              <v-row>
                <v-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="title"
                  >
                    <v-text-field
                      v-model="newItem.title"
                      hide-details="auto"
                      label="Título"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="nextQuestionId"
                  >
                    <v-autocomplete
                      v-model="newItem.nextQuestionId"
                      item-value="id"
                      item-text="title"
                      label="Próxima Pergunta"
                      hide-details="auto"
                      clearable
                      :items="questions"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="order"
                  >
                    <v-text-field
                      v-model="newItem.order"
                      hide-details="auto"
                      label="Ordem"
                      v-mask="'###'"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="explain"
                  >
                    <v-switch
                      v-model="newItem.explain"
                      label="Justificar"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import questionsApi from '@/api/questions'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: () => { },
      },
      maxOrder: {
        type: Number,
        default: 0,
      },
      surveyId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        questions: [],
        newItem: {
          title: null,
          explain: false,
          order: 0
        }
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        this.loadQuestions()

        if (val && this.item) {
          this.newItem = { ...this.item }
        } else {
          this.resetItem()
        }
      }
    },

    methods: {
      save () {
        const event = this.item ? 'update' : 'insert'

        const newItem = { ...this.newItem }
        
        if (newItem.nextQuestionId) {
          newItem.nextQuestion = this.questions.find(question => question.id === newItem.nextQuestionId)
        } else {
          newItem.nextQuestion = null
        }

        this.$emit(event, newItem)

        this.show = false

        this.resetItem()
      },

      resetItem () {
        this.newItem = {}
        this.newItem.title = null
        this.newItem.explain = false
        this.newItem.order = this.maxOrder + 1
      },

      async loadQuestions () {
        try {
          this.loadingGet = true

          const response = await questionsApi.list(this.surveyId)

          this.questions = response.data.questions
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingGet = false
        }
      },
    }

  }
</script>
